<template>
  <div style="padding-top: 10px">
    <el-row>
      <el-col :span="24">
        <!--        筛选条件-->
        <SearchCommon @doSearch="search" style="display: inline" @addBtn="addBtn" @showLog="showLog"/>
      </el-col>
    </el-row>

    <CaseRecordList ref="refCaseRecordList" :search-condition="searchCondition" @editRow="editRow"
                    @showLog="showRowLog"/>
    <el-row>
      <el-col :span="24">
        <!--        操作步骤-->
        <Tips :data="tipsList"/>
      </el-col>
    </el-row>

    <!--    数据录入（添加）-抽屉-->
    <ApeDrawer :drawerData="drawerData" @drawerOpen="drawerOpen" @drawerClose="drawerClose"
               @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <CreateRecord ref="refCreateRecord" @close="drawerClose" :edit-form-data="editFormData"/>
      </template>
    </ApeDrawer>

    <!--    日志-->
    <CaseRecordLog :visible="logSetting.visible" @close="closeLog" :info-id="logForm.info_id||null"
                   v-if="userPermissions.indexOf('case_record_log') !== -1"/>

  </div>
</template>

<script>

import SearchCommon from "./SearchCommon"
import CaseRecordList from "./CaseRecordList"
import { mapGetters } from "vuex";

import CreateRecord from "./CreateRecord"
import ApeDrawer from "@/components/ApeDrawer";
import CaseRecordLog from "./CaseRecordLog"
import Tips from "@/components/Tips";

export default {
  name: "TiktokData",
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    SearchCommon, CaseRecordList, ApeDrawer, CreateRecord, CaseRecordLog, Tips
  },
  data() {
    return {
      tipsList: [
        '右键菜单：编辑、日志',
        '录入：需要录入权限和数据权限',
        '查看：全员'
      ],
      searchCondition: {},
      dialogVisible: false,
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '1000px',
        show_footer: false,
        // mask: false,
        close_name: '关 闭',
        // confirm_name: '打 印',
      },
      editId: null,
      editFormData: {},
      logSetting: {
        visible: false,
      },
      logForm: {}
    }
  },
  methods: {
    //
    search(val) {
      // console.log('search', val)
      this.searchCondition = val
    },
    editRow(row) {
      // console.log('row id', this.editId)
      this.editFormData = { ...row }
      // this.dialogVisible = true
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `抖音视频案例数据-编辑`
      this.drawerData.visible = true
      this.drawerData.loading = false

    },
    addBtn() {
      // this.showEdit = true
      // this.$emit('addBtn')
      this.editFormData = {}
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `抖音视频案例数据-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
    },
    drawerOpen() {
      //抽屉打开
      this.$refs['refCreateRecord'].init()
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.$refs['refCaseRecordList'].initList()
    },
    drawerConfirm() {

    },
    refresh() {
      this.$refs['refCaseRecordList'].initList()
    },
    showLog() {
      this.logForm = {}
      this.logSetting.visible = true
    },
    showRowLog(row) {
      this.logForm.info_id = row.id
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
  }
}
</script>

<style scoped>

</style>


